import { Button } from 'antd'
import Link from 'next/link'
import { URL_TEMPLATE } from 'src/libs/constant'

const HeaderTutor = () => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='header-layout__title'>
          <h4>Lịch dạy hôm nay</h4>
          <div>
            <p style={{ lineHeight: '22px', fontSize: '15px', marginBottom: '0', fontWeight: '400' }}>
              Chuẩn bị bài trước buổi học để nâng cao
            </p>
            <p style={{ lineHeight: '22px', fontSize: '15px', marginBottom: '0', fontWeight: '400' }}>
              chất lượng giảng dạy, tiến bộ học sinh
            </p>
          </div>
        </div>
        <div className='header-layout__description'>
          <ul className='pl-3'>
            <li style={{ lineHeight: '22px', fontSize: '15px', color: '#11638E' }}>Xem nhanh lịch dạy </li>
            <li style={{ lineHeight: '22px', fontSize: '15px', color: '#11638E' }}>Xem bài tập về nhà</li>
          </ul>
          <ul>
            <li style={{ lineHeight: '22px', fontSize: '15px', color: '#11638E' }}>Xem thông tin buổi học</li>
            <li style={{ lineHeight: '22px', fontSize: '15px', color: '#11638E' }}>Xem nội dung bài giảng</li>
          </ul>
        </div>
        <Link href={`${URL_TEMPLATE.SESSION_TEACHING_SCHEDULE}`}>
          <Button
            style={{
              color: 'white',
              borderRadius: '20px',
              marginLeft: '10px',
              background:
                'linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(255,206,55,1) 0%, rgba(255,110,22,1) 100%)',
              border: 'none',
              fontWeight: '500',
              padding: '2px 25px',
              height: '38px',
              fontSize: '17px',
            }}
          >
            Dùng ngay
          </Button>
        </Link>
      </div>
    </>
  )
}

export default HeaderTutor
