import React, { useMemo, useState } from 'react'
import { Menu } from 'antd'
import Link from 'next/link'
import Icon, {
  SettingOutlined,
  CalendarOutlined,
  SolutionOutlined,
  CheckSquareOutlined,
  UserOutlined,
  ClockCircleOutlined,
  ReadOutlined,
  ApiOutlined,
  ProfileOutlined,
  DashboardOutlined,
  CalculatorOutlined,
  FileOutlined,
  ContactsOutlined,
  PhoneOutlined
} from '@ant-design/icons'

import { useTranslation } from 'next-i18next'
import { getUser } from 'src/libs/browser'
import { hasRequiredItem } from 'src/libs/util'
import { URL_TEMPLATE } from 'src/libs/constant'
import { useTracking } from 'src/hooks/useTracking'
import { TRACKING_EVENT_NAME } from 'src/libs/constant/tracking'

const { SubMenu } = Menu
export function SidebarMenu ({ sidebarIsCollapsed }) {
  const { pathname } = window.location
  const user = getUser()
  const userRoles = user?.userRoles

  const permissions = useMemo(() => {
    return {
      canUseUserModule: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'manager-teacher', 'manager-sale']),
      canUseTeacherUser: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'manager-teacher', 'manager-sale', 'employee-customer-care']),
      canUseClassDemoMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-monitor', 'manager-teacher', 'employee-teacher', 'manager-sale', 'employee-sale', 'employee-customer-care-specialist']),
      canUseAssignClassDemoMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-monitor', 'manager-teacher', 'employee-teacher', 'manager-sale', 'employee-sale']),
      canUseClassRegularMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-monitor', 'manager-teacher', 'employee-teacher', 'employee-sale', 'manager-sale', 'employee-collaborator-class', 'employee-customer-care', 'employee-customer-care-specialist']),
      canUseMakeupLessonMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-sale', 'manager-sale', 'employee-collaborator-class', 'employee-customer-care', 'employee-customer-care-specialist']),
      canUseTeacherBookingMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-customer-care', 'employee-collaborator-class', 'manager-teacher', 'employee-teacher']),
      canUseTeacherCancelSchedule: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-teacher']),
      canUseSessionSummaryMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-collaborator-class', 'manager-teacher', 'employee-customer-care']),
      canUseSessionRegularSummaryMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-collaborator-class', 'manager-teacher', 'employee-customer-care', 'employee-customer-care-specialist']),
      canUseConfigCalendarTeacherMenu: hasRequiredItem(userRoles, ['admin']),
      canUseMonitorTicketMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-monitor', 'manager-sale', 'employee-sale', 'employee-customer-care', 'employee-collaborator-class']),
      canUseTechnicalTicketMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'manager-teacher', 'employee-technical', 'employee-ministry', 'employee-teacher', 'employee-monitor', 'manager-sale', 'employee-sale', 'employee-collaborator-class', 'employee-customer-care-specialist']),
      canUseRescheduleDemoTickketMenu:  hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-monitor']),
      canUseFeedBackDemoMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'manager-teacher', 'employee-teacher', 'manager-sale', 'employee-sale', 'employee-collaborator-class']),
      canUseFeedBackSessionMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-teacher', 'employee-customer-care']),
      canUseReportResultMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'manager-teacher', 'employee-teacher', 'manager-sale', 'employee-customer-care', 'employee-sale']),
      canUseCareTicketMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-customer-care', 'manager-teacher', 'employee-teacher', 'manager-sale', 'employee-sale']),
      canUseAssignRegClassMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-sale', 'manager-sale', 'employee-collaborator-class', 'employee-customer-care', 'employee-customer-care-specialist']),
      canUseClassRegularSessionMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-customer-care', 'employee-monitor', 'manager-teacher', 'employee-teacher', 'manager-sale', 'employee-sale', 'employee-customer-care-specialist']),
      canUseTeachingSchedule: hasRequiredItem(userRoles, ['employee-teacher']),
      canUseSearchSessionHomework: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-monitor', 'manager-teacher', 'employee-teacher', 'manager-sale', 'employee-sale', 'employee-customer-care-specialist']),
      canUseStudentMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-customer-care', 'employee-sale', 'manager-sale', 'manager-teacher', 'employee-teacher', 'employee-collaborator-class', 'employee-customer-care-specialist']),
      canUseStudentCareMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'manager-sale', 'employee-customer-care-specialist', 'employee-customer-care']),
      canUseStudentCareTicket: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'manager-sale', 'employee-customer-care-specialist']),
      canUseStudentCarePeriodicMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'employee-ministry', 'employee-customer-care-specialist']),      
      canUseStudentExpireMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'employee-ministry', 'employee-sale', 'manager-sale','employee-customer-care-specialist']),
      canUpdateClassinCookie: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor']),
      canUseUserConfig: hasRequiredItem(userRoles, ['admin', 'manager']),
      canUseConfigLearningModel: hasRequiredItem(userRoles, ['admin']),
      canUseTutorConfig: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher']),      
      canUseStudentTransferMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-collaborator-class', 'employee-customer-care', 'manager-sale' ]),
      canUseBreachRulesConfig: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher']),
      canUseSalaryConfig: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher']),           
      canUseWaitingConfirm: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-monitor', 'manager-teacher', 'employee-teacher']),
      canUseFindTeacherBackup: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-teacher']),
      canUseListObserveBackup: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-teacher']),
      canUseDutyBackup: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher']),
      canUseAbsenceMenu: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-teacher']),
      canUseRegisterTeacherAbsence: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-teacher']),
      canUseTeacherQuiJobAbsence: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher']),
      canUseManageTeacherAbsence: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-teacher']),
      canUseManageTeacherAndQualityControl: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-teacher', 'employee-ministry', 'quality-control', 'quality-assurance']),
      canUseTutorQualityReport: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-teacher']),
      canUseManageCancelSession: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher']),
      canUseSalaryConfig: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher']),       
      canUseOperate: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-monitor', 'employee-ministry', 'employee-monitor', 'employee-technical', 'employee-collaborator-class', 'employee-customer-care', 'manager-teacher', 'employee-teacher', 'manager-sale', 'employee-sale']),   
      canUseMakeUpSessionNumberConfig: hasRequiredItem(userRoles, ['admin']),
      canUseQualityControlSession: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-ministry', 'quality-control', 'employee-teacher']),
      canUseQualityAssuranceSession: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-ministry', 'quality-assurance', 'employee-teacher']),
      canUseQualityControlSystem: hasRequiredItem(userRoles, ['admin', 'manager', 'manager-teacher', 'employee-ministry', 'quality-control']),
      canUseCustomSurvey: hasRequiredItem(userRoles, ['admin', 'manager', 'employee-customer-care']),
    }
  }, [user])

  const { onTitleClick, opened } = useControlOpenedMenu(sidebarIsCollapsed)
  const defaultSelectedKeys = []
  const { t } = useTranslation()

  const {handleTracking} = useTracking()

  const getMenu = (menu) => {
    if (!menu) return null
    return menu.map(item => {
      const { title, subMenu, icon, validator = () => true, link } = item
      const key = item.key || item.link
      if (validator(permissions)) {
        if (link) {
          if (pathname.startsWith(key)) {
            defaultSelectedKeys.push(key)
          }

          const handleClickMenu = ({key}) => {
            switch (key){
              case URL_TEMPLATE.SESSION_TEACHING_SCHEDULE:
                return handleTracking(TRACKING_EVENT_NAME.NUMBER_CLICKS_TODAYCLASSSCHEDULE)
              default:
                return
            }
          }
          return <Menu.Item onClick={handleClickMenu} key={key}><Link href={link}><a title={t(title)}>{t(title)}</a></Link></Menu.Item>
        } else {
          return (
            <SubMenu key={key} icon={<Icon component={icon} />} title={t(title)} onTitleClick={onTitleClick.bind(null, key)}>
              {getMenu(subMenu)}
            </SubMenu>
          )
        }
      }
      return null
    })
  }
  return (
    <Menu theme='dark' mode='inline' defaultSelectedKeys={defaultSelectedKeys} selectedKeys={defaultSelectedKeys} openKeys={opened}>
      {getMenu(menu)}
    </Menu>
  )
}

const useControlOpenedMenu = (sidebarIsCollapsed) => {
  const { pathname } = window.location
  const matchOpen = pathname.match(/(^\/[^/]+)/)
  const [openedKey, setOpenKey] = useState([matchOpen && matchOpen[0]])
  const onTitleClick = (key) => {
    if (!sidebarIsCollapsed) {
      if (openedKey[0] !== key) {
        setOpenKey([key])
      } else {
        setOpenKey([])
      }
    }
  }
  return {
    opened: sidebarIsCollapsed ? undefined : openedKey,
    onTitleClick
  }
}

const menu = [
  {
    title: 'component.menu.calendar',
    icon: CalendarOutlined,
    key: '/calendar',
    validator (permissions) {
      return permissions.canUseTeacherBookingMenu || permissions.canUseSessionSummaryMenu || permissions.canUseSessionRegularSummaryMenu
    },
    subMenu: [
      {
        title: 'component.menu.calendar_teacher',
        link: URL_TEMPLATE.CALENDAR_TEACHER_LIST,
        validator (permissions) {
          return permissions.canUseTeacherBookingMenu
        }
      },
      {
        title: 'component.menu.cancel_calendar_teacher',
        link: URL_TEMPLATE.CALENDAR_TEACHER_CANCEL_SCHEDULE,
        validator (permissions) {
          return permissions.canUseTeacherCancelSchedule
        }
      },
      {
        title: 'component.menu.calendar_demo_summary',
        link: URL_TEMPLATE.CALENDAR_SUMMARY_DEMO,
        validator (permissions) {
          return permissions.canUseSessionSummaryMenu
        }
      },
      {
        title: 'component.menu.calendar_regular_summary',
        link: URL_TEMPLATE.CALENDAR_SUMMARY_REGULAR,
        validator (permissions) {
          return permissions.canUseSessionRegularSummaryMenu
        }
      }
    ]
  },
  {
    title: 'component.menu.assign_class',
    icon: ClockCircleOutlined,
    key: '/assign-class',
    validator (permissions) {
      return permissions.canUseAssignClassDemoMenu || permissions.canUseAssignRegClassMenu || permissions.canUseMakeupLessonMenu
    },
    subMenu: [
      {
        title: 'component.menu.assign_class_demo',
        link: URL_TEMPLATE.ASSIGN_CLASS_DEMO_LIST,
        validator (permissions) {
          return permissions.canUseAssignClassDemoMenu
        }
      },
      {
        title: 'component.menu.assign_class_regular',
        link: URL_TEMPLATE.ASSIGN_CLASS_REGULAR_LIST,
        validator (permissions) {
          return permissions.canUseAssignRegClassMenu
        }
      },
      {
        title: 'component.menu.assign_makeup_lesson',
        link: URL_TEMPLATE.ASSIGN_CLASS_MAKEUP_LESSON_LIST,
        validator (permissions) {
          return permissions.canUseMakeupLessonMenu
        }
      }
    ]
  },
  {
    title: 'component.menu.class',
    icon: ReadOutlined,
    key: '/class',
    validator (permissions) {
      return permissions.canUseClassDemoMenu || permissions.canUseClassRegularMenu
    },
    subMenu: [
      {
        title: 'component.menu.class_demo',
        link: URL_TEMPLATE.CLASS_DEMO_LIST,
        validator (permissions) {
          return permissions.canUseClassDemoMenu
        }
      },
      {
        title: 'component.menu.class_regular',
        link: URL_TEMPLATE.CLASS_REGULAR_LIST,
        validator (permissions) {
          return permissions.canUseClassRegularMenu
        }
      }
    ]
  },
  {
    title: 'component.menu.session',
    icon: SolutionOutlined,
    key: '/session',
    validator (permissions) {
      return permissions.canUseClassRegularSessionMenu
    },
    subMenu: [
      {
        title: 'component.menu.session_regular',
        link: URL_TEMPLATE.SESSION_REGULAR_LIST,
        validator (permissions) {
          return permissions.canUseClassRegularSessionMenu
        }
      },
      {
        title: 'component.menu.teaching_schedule_today',
        link: URL_TEMPLATE.SESSION_TEACHING_SCHEDULE,
        validator (permissions) {
          return permissions.canUseTeachingSchedule
        }
      },
      {
        title: 'component.menu.session_homework',
        link: URL_TEMPLATE.SESSION_HOMEWORK_LIST,
        validator (permissions) {
          return permissions.canUseSearchSessionHomework
        }
      }
    ]
  },
  {
    title: 'component.menu.waiting_confirm',
    icon: CheckSquareOutlined,
    key: '/waiting_confirm',
    validator (permissions) {
      return permissions.canUseWaitingConfirm
    },
    subMenu: [
      {
        title: 'component.menu.waiting_confirm_class',
        link: URL_TEMPLATE.WAITING_CONFIRM_LIST,
        validator (permissions) {
          return permissions.canUseWaitingConfirm
        }
      },
      {
        title: 'component.menu.teacher_receive_backup',
        link: URL_TEMPLATE.RECEIVE_BACKUP,
        validator (permissions) {
          return permissions.canUseFindTeacherBackup
        }
      },
      {
        title: 'component.menu.duty_schedule_backup',
        link: URL_TEMPLATE.DUTY_SCHEDULE_BACKUP,
        validator (permissions) {
          return permissions.canUseDutyBackup
        }
      },
      {
        title: 'component.menu.list_observe_backup',
        link: URL_TEMPLATE.LIST_OBSERVE_BACKUP,
        validator (permissions) {
          return permissions.canUseListObserveBackup
        }
      }
    ]
  },
  {
    title: 'component.menu.teacher_absence_or_cancel_session',
    icon: FileOutlined,
    key: '/absence',
    validator (permissions) {
      return permissions.canUseAbsenceMenu
    },
    subMenu: [
      {
        title: 'component.menu.register_teacher_absence',
        link: URL_TEMPLATE.REGISTER_ABSENCE,
        validator (permissions) {
          return permissions.canUseRegisterTeacherAbsence
        }
      },
      {
        title: 'component.menu.manage_teacher_absence',
        link: URL_TEMPLATE.MANAGE_ABSENCE_TEACHER,
        validator (permissions) {
          return permissions.canUseManageTeacherAbsence
        }
      }     
    ]
  },
  {
    title: 'component.menu.absence_homeroom_class',
    icon: ProfileOutlined,
    key: '/absence-homeroom-class',
    validator (permissions) {
      return permissions.canUseAbsenceMenu
    },
    subMenu: [
      {
        title: 'component.menu.register_absence_homeroom_class',
        link: URL_TEMPLATE.ABSENCE_HOMEROOM_CLASS,
        validator (permissions) {
          return permissions.canUseManageTeacherAbsence
        }
      },
      {
        title: 'component.menu.list_cancel_homeroom_class',
        link: URL_TEMPLATE.MANAGE_ABSENCE_HOMEROOM_CLASS,
        validator (permissions) {
          return permissions.canUseManageTeacherAbsence
        }
      },
      {
        title: 'component.menu.cancel_class_quit_job',
        link: URL_TEMPLATE.CANCEL_CLASS_QUIT_JOB,
        validator (permissions) {
          return permissions.canUseTeacherQuiJobAbsence
        }
      }
    ]
  },
  {
    title: 'component.menu.support_quit_job',
    icon: PhoneOutlined,
    key: '/support',
    validator (permissions) {
      return permissions.canUseAbsenceMenu
    },
    subMenu: [
      {
        title: 'component.menu.support_request',
        link: URL_TEMPLATE.SUPPORT_QUIT_JOB,
        validator (permissions) {
          return permissions.canUseManageTeacherAbsence
        }
      },
      {
        title: 'component.menu.manager_support_quit_job',
        link: URL_TEMPLATE.MANAGE_SUPPORT_QUIT_JOB,
        validator (permissions) {
          return permissions.canUseManageTeacherAbsence
        }
      },
    ]
  },
  {
    title: 'component.menu.operation',
    icon: DashboardOutlined,
    key: '/operation',
    validator (permissions) {
      return permissions.canUseOperate
    },
    subMenu: [
      {
        title: 'component.menu.operation_monitor',
        link: URL_TEMPLATE.OPERATION_MONITOR_LIST,
        validator (permissions) {
          return permissions.canUseMonitorTicketMenu
        }
      },
      {
        title: 'component.menu.operation_technical',
        link: URL_TEMPLATE.OPERATION_TECHNICAL_LIST,
        validator (permissions) {
          return permissions.canUseTechnicalTicketMenu
        }
      },
      {
        title: 'component.menu.reschedule_demo',
        link: URL_TEMPLATE.OPERATION_RESCHEDULE_DEMO_LIST,
        validator (permissions) {
          return permissions.canUseRescheduleDemoTickketMenu
        }
      }
    ]

  },
  {
    title: 'component.menu.learn_result',
    icon: CalculatorOutlined,
    key: '/learn-result',
    validator (permissions) {
      return permissions.canUseFeedBackDemoMenu || permissions.canUseFeedBackSessionMenu
    },
    subMenu: [
      {
        title: 'component.menu.statistics_report',
        link: URL_TEMPLATE.STATISTICS_REPORT,
        validator (permissions) {
          return permissions.canUseFeedBackSessionMenu
        }
      },
      {
        title: 'component.menu.learn_result_demo',
        link: URL_TEMPLATE.LEARN_RESULT_DEMO_LIST,
        validator (permissions) {
          return permissions.canUseFeedBackDemoMenu
        }
      }
    ]
  },

  {
    title: 'component.menu.user_information',
    icon: ContactsOutlined,
    key: '/user-information',
    validator (permissions) {
      return permissions.canUseManageTeacherAndQualityControl
    },
    subMenu: [
      {
        title: 'component.menu.income_information',
        link: URL_TEMPLATE.USER_INFORMATION,
        validator (permissions) {
          return permissions.canUseManageTeacherAbsence
        }
      },
      {
        title: 'component.menu.tutor_quality_report',
        link: URL_TEMPLATE.TUTOR_QUALITY_REPORT,
        validator (permissions) {
          return permissions.canUseTutorQualityReport
        }
      },
      {
        title: 'component.menu.quality_assurance',
        link: URL_TEMPLATE.QUALITY_ASSURANCE_SESSION,
        validator (permissions) {
          return permissions.canUseQualityAssuranceSession
        }
      },
      {
        title: 'component.menu.quality_control',
        link: URL_TEMPLATE.QUALITY_CONTROL_SESSION,
        validator (permissions) {
          return permissions.canUseQualityControlSession
        }
      }
    ],
  },

  // {
  //   title: 'component.menu.student_care_v1',
  //   icon: () => <img style={{width:14,height:14}} src='/image/customer-support-icon.svg'/> ,
  //   key: '/care-student',
  //   validator (permissions) {
  //     return permissions.canUseStudentCareMenu
  //   },
  //   subMenu: [
  //     {
  //       title: 'component.menu.care_student_first',
  //       link: URL_TEMPLATE.STUDENT_CARE_FIRST,
  //       validator (permissions) {
  //         return permissions.canUseStudentCareMenu
  //       }
  //     },
  //     {
  //       title: 'component.menu.care_student_second',
  //       link: URL_TEMPLATE.STUDENT_CARE_SECOND,
  //       validator (permissions) {
  //         return permissions.canUseStudentCareMenu
  //       }
  //     },
  //     {
  //       title: 'component.menu.care_student_third',
  //       link: URL_TEMPLATE.STUDENT_CARE_THIRD,
  //       validator (permissions) {
  //         return permissions.canUseStudentCareMenu
  //       }
  //     }
  //   ],
  // },
  {
    title: 'component.menu.care_student',
    icon: () => <img style={{ width:14, height:14 }} src='/image/customer-support-icon.svg'/> ,
    key: '/student-care',
    validator (permissions) {
      return permissions.canUseStudentCareMenu
    },
    subMenu: [
      {
        title: 'component.menu.student_care_ticket',
        link: URL_TEMPLATE.STUDENT_CARE_TICKET,
        validator (permissions) {
          return permissions.canUseStudentCareTicket
        }
      },
      {
        title: 'component.menu.student_care_periodic_ticket',
        link: URL_TEMPLATE.STUDENT_CARE_PERIODIC_TICKET,
        validator (permissions) {
          return permissions.canUseStudentCarePeriodicMenu
        }
      },
      {
        title: 'component.menu.extended_care_student',
        link: URL_TEMPLATE.STUDENT_LIST_ABOUT_TO_EXPIRE,
        validator (permissions) {
          return permissions.canUseStudentExpireMenu
        }
      },
      {
        title: 'component.menu.customer_survey',
        link: URL_TEMPLATE.CUSTOMER_SURVEY,
        validator (permissions) {
          return permissions.canUseCustomSurvey
        }
      },
    ],
  },
  {
    title: 'component.menu.students',
    icon: UserOutlined,
    key: '/students',
    validator (permissions) {
      return permissions.canUseStudentMenu
    },
    subMenu: [
      {
        title: 'component.menu.students_list',
        link: URL_TEMPLATE.STUDENT_LIST,
        validator (permissions) {
          return permissions.canUseStudentMenu
        }
      },
      {
        title: 'component.menu.students_transfer',
        link: URL_TEMPLATE.STUDENT_TRANSFER,
        validator (permissions) {
          return permissions.canUseStudentTransferMenu
        }
      }
    ],
  },
  
  {
    title: 'component.menu.manage',
    icon: SettingOutlined,
    key: '/manage',
    validator (permissions) {
      return permissions.canUseUserModule || permissions.canUseTeacherUser
    },
    subMenu: [
      {
        title: 'component.menu.manage_user',
        link: URL_TEMPLATE.MANAGE_USER_LIST,
        validator (permissions) {
          return permissions.canUseUserModule
        }
      },
      {
        title: 'component.menu.manage_teacher',
        link: URL_TEMPLATE.MANAGE_TEACHER_LIST,
        validator (permissions) {
          return permissions.canUseTeacherUser
        }
      },
      {
        title: 'component.menu.manage_homeroom_teacher',
        link: URL_TEMPLATE.MANAGE_HOMEROOM_TEACHER,
        validator (permissions) {
          return permissions.canUseUserConfig
        }
      },
      {
        title: 'component.menu.manage_role',
        link: URL_TEMPLATE.MANAGE_ROLE_LIST,
        validator (permissions) {
          return permissions.canUseUserModule
        }
      }
      /**
       * DEPRECATED: Bỏ, không dùng
       */
      // {
      //   key: '/manage/advance',
      //   title: 'component.menu.manage_advance',
      //   link: URL_TEMPLATE.MANAGE_ADVANCE,
      //   validator (permissions) {
      //     return permissions.canUpdateClassinCookie
      //   }
      // }
    ]
  },
  {
    title: 'component.menu.configuration',
    icon: ApiOutlined,
    key: '/config',
    validator (permissions) {
      return permissions.canUseTutorConfig
    },
    subMenu: [
      {
        title: 'component.menu.calendar_teacher_config_time_slot',
        link: URL_TEMPLATE.CALENDAR_TEACHER_CONFIG_TIME_SLOT,
        validator (permissions) {
          return permissions.canUseConfigCalendarTeacherMenu
        }
      },
      {
        title: 'component.menu.calendar_teacher_config_frame',
        link: URL_TEMPLATE.CALENDAR_TEACHER_CONFIG_FRAME,
        validator (permissions) {
          return permissions.canUseConfigCalendarTeacherMenu
        }
      },
      {
        title: 'component.menu.configuration_region',
        link: URL_TEMPLATE.CONFIG_REGION,
        validator (permissions) {
          return permissions.canUseConfigCalendarTeacherMenu
        }
      },
      {
        title: 'component.menu.configuration_province',
        link: URL_TEMPLATE.CONFIG_PROVINCE,
        validator (permissions) {
          return permissions.canUseConfigCalendarTeacherMenu
        }
      },
      {
        title: 'component.menu.configuration_school',
        link: URL_TEMPLATE.CONFIG_SCHOOL,
        validator (permissions) {
          return permissions.canUseConfigCalendarTeacherMenu
        }
      },
      {
        title: 'component.menu.configuration_device',
        link: URL_TEMPLATE.CONFIG_DEVICE,
        validator (permissions) {
          return permissions.canUseConfigCalendarTeacherMenu
        }
      },
      {
        title: 'component.menu.configuration_grade',
        link: URL_TEMPLATE.CONFIG_GRADE,
        validator (permissions) {
          return permissions.canUseConfigCalendarTeacherMenu
        }
      },
      {
        title: 'component.menu.configuration_level',
        link: URL_TEMPLATE.CONFIG_LEVEL,
        validator (permissions) {
          return permissions.canUseConfigCalendarTeacherMenu
        }
      },
      {
        title: 'component.menu.configuration_subject',
        link: URL_TEMPLATE.CONFIG_SUBJECT,
        validator (permissions) {
          return permissions.canUseConfigCalendarTeacherMenu
        }
      },
      {
        title: 'component.menu.configuration_study_program',
        link: URL_TEMPLATE.CONFIG_STUDY_PROGRAM,
        validator (permissions) {
          return permissions.canUseUserConfig
        }
      },
      {
        title: 'component.menu.crm_learning_model',
        link: URL_TEMPLATE.CONFIG_LEARNING_MODEL,
        validator (permissions) {
          return permissions.canUseConfigLearningModel
        }
      },
      {
        title: 'component.menu.tutor_file',
        link: URL_TEMPLATE.TUTOR_FILE_LIST,
        validator (permissions) {
          return permissions.canUseTutorConfig
        }
      },
      {
        title: 'component.menu.breach_rules',
        link: URL_TEMPLATE.CONFIG_BREACH_RULES,
        validator (permissions) {
          return permissions.canUseBreachRulesConfig
        }
      },
      {
        title: 'component.menu.salary',
        link: URL_TEMPLATE.CONFIG_SALARY,
        validator (permissions) {
          return permissions.canUseSalaryConfig
        }
      },
      {
        title: 'component.menu.make_up_session_number',
        link: URL_TEMPLATE.CONFIG_MAKE_UP_SESSION_NUMBER,
        validator (permissions) {
          return permissions.canUseMakeUpSessionNumberConfig
        }
      },
      {
        title: 'component.menu.config_teacher_manage',
        link: URL_TEMPLATE.CONFIG_TEACHER_MANAGE,
        validator (permissions) {
          return permissions.canUseTutorConfig
        }
      },
      {
        title: 'component.menu.configuration_tutor_tracking_metrics',
        link: URL_TEMPLATE.CONFIG_TUTOR_TRACKING_METRICS,
        validator (permissions) {
          return permissions.canUseTutorConfig
        }
      },
      {
        title: 'component.menu.configuration_component_score_weights',
        link: URL_TEMPLATE.CONFIG_COMPONENT_SCORE_WEIGHTS,
        validator (permissions) {
          return permissions.canUseTutorConfig
        }
      },
      {
        title: 'component.menu.configuration_component_folder',
        link: URL_TEMPLATE.CONFIG_COMPONENT_FOLDER_CLASSIN,
        validator (permissions) {
          return permissions.canUseUserConfig
        }
      }
    ]
  }
]
