import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useModalState } from "src/hooks/useModalState";
import { isShowModalState } from "src/libs/browser";
import { LINK_REMIND, LINK_REMIND_TUTOR, URL_TEMPLATE } from "src/libs/constant";
import Link from "next/link";

export function ModalBannerWhenLogin() {
  const { t } = useTranslation();
  const { isModalVisible, handleClose, handleOpen } = useModalState();
  useEffect(() => {
    if (isShowModalState()) {
      handleOpen()
      document.cookie = `showModal=0; path=/`;
    }
    return () => {
      handleCloseModal()
    }
  }, [])

  const handleCloseModal = () => {
    document.cookie = `showModal=0; path=/`;
    handleClose();
  };
  return (
    <Modal 
      visible={isModalVisible} 
      destroyOnClose
      width={580}
      footer={null}
      className="banner-when-login__container"
    >
      <div className="banner-when-login__wrap">
        <div className="banner-when-login__btn-close">
          <Button 
            style={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              backgroundColor: '#4A32B4',
              color: '#8E74FD',
              fontWeight: '900',
              border: 'none',
              height: '33px',
              width: '35px',
            }}
            onClick={handleCloseModal}
          >X</Button>
        </div>
        <div>
          {/* <img src="/image/background-cloud.png" alt="cloud" className="background-cloud"/> */}
          <div className="banner-when-login__remind">
            <div className="banner-when-login__remind-wrap">
              <span className="banner-when-login__remind-student">Học sinh bạn đang dạy sẽ</span>&nbsp;&nbsp;
              <span className="banner-when-login__remind-no-progress">Không tiến bộ</span>
            </div>
            <div className="banner-when-login__remind-content">
              <span className="banner-when-login__remind-content-warning">nếu không làm</span>&nbsp;
              <span className="banner-when-login__remind-content-homework">BÀI TẬP VỀ NHÀ</span>
            </div>
          </div>
        </div>
        <img src="/image/avatar-student.png" alt="avatar-student" className="avatar-student"/>
        <div className="banner-when-login__content-description">
          <div className="banner-when-login__description">
            <p>Học sinh không làm bài tập nếu bạn không kiểm tra và nhắc nhở</p>
            <div className="description">Hãy kiểm tra bài tập và nhắc học sinh làm bài trong buổi học theo kịch bản mới nhé!</div>
            <img src="/image/light-bulb.png" alt="light-bulb" className="light-bulb"/>
          </div>
          <div className="banner-when-login__button">
            <Link href={LINK_REMIND_TUTOR}>
              <a target="_blank" className="navigate__btn-remind">
                <button onClick={handleCloseModal} className="btn-remind">
                    Kịch bản nhắc nhở
                </button>
              </a>
            </Link>
            <Link href={`${URL_TEMPLATE.SESSION_TEACHING_SCHEDULE}`}>
              <Button onClick={handleCloseModal} Button className="btn-check">Kiểm tra ngay</Button>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
}
