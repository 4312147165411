import { appWithTranslation } from 'next-i18next'
import Head from 'next/head'
import ErrorBoundary from 'src/components/fallback/ErrorBoundary'
import { AppContext } from 'src/context'

import { AppLayout } from 'src/components/layout/index'

import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useRouter } from 'next/router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'src/redux/store'
import { ModalBannerWhenLogin } from 'src/components/modal/ModalBannerWhenLogin'
import { ModalNotificationHomeWork } from 'src/components/modal/ModalNotificationHomeWork'
import { isLoggedIn } from 'src/libs/browser'
import { ModalRemindTutor } from 'src/components/modal/ModalRemindTutor'
import { ModalRemindMonthlyExam } from 'src/components/modal/ModalRemindMonthlyExam'
import { useGetData } from 'src/hooks/useGetData'
import commonApi from 'src/api/commonApi'
import { useMemo } from 'react'
import moment from 'moment'

const App = ({ Component, pageProps }) => {
  const title = 'Edupia Tutor - LMS'
  const { asPath } = useRouter()
  const { data } = useGetData(commonApi.getDateMonthlyExam, null , { isDisabled: !isLoggedIn() })

  const isShowModalMonthlyExam = useMemo(() => {
    const { startDate, endDate } = data || {}
    const dateNow = moment().format('YYYYMMDD')
    return startDate <= dateNow && dateNow <= endDate
  }, [data])

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={<div>loading</div>} persistor={persistor}>
          <Head>
            <title>{title}</title>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1'
            />
            <meta property='og:title' content={title} />
          </Head>
          <AppLayout {...pageProps}>
            <AppContext.Provider value={{}}>
              <ErrorBoundary url={asPath}>
                <Component {...pageProps} />
              </ErrorBoundary>
            </AppContext.Provider>
          </AppLayout>
        </PersistGate>
      </Provider>
      {!!data && (isShowModalMonthlyExam ? <ModalRemindMonthlyExam /> : <ModalBannerWhenLogin />)}
      {/* <ModalRemindMonthlyExam />
      <ModalBannerWhenLogin /> */}
      {/* <ModalRemindTutor /> */}
      {isLoggedIn() && <ModalNotificationHomeWork/>}
    </>
  )
}

export default appWithTranslation(App)
